export const CONTENT =
  {
    intro: 'Junious “House” Brickhouse is an internationally established educator, choreographer and cultural preservationist with over 30 years of experience in Urban Dance Culture.',
    childhood: 'I am a tradition-bearer, and as a tradition-bearer I have a charge to sustainability. That requires connecting with people and creating the infrastructure in which our minds and our diversity can thrive. Having started dancing in the 70’s, I’m from a time where we were motivated differently. Our motivation was not stardom, but rather to be seen and to be respected. I’m from humble beginnings, and I’ve come a long way. Although I’ve enjoyed the journey, the work isn’t done.',
    mother: 'Lynda Marlene Brickhouse, my mom, was my first dance teacher and a damn good one at that. What I remember most about those days is...I remember her laugh...especially when I was sure I was doing the move correctly. She would just do it over and over again, until I got it right. That smile when she saw you succeed...was healing. Those who knew her, remember that smile. Funny, when I think about it, she taught survival when she taught you anything. I am grateful that Lynda is my teacher.',
    army: 'At age 18, I embarked on an over 20-year career as a logistics professional in the U.S. Army and later as a Department of Defense contractor. Throughout this time in various international assignments, I developed both a military and dance career, eventually rising to positions of leadership and responsibility in both areas. The logistics skills and leadership acumen I honed in his military work provide a solid and unique foundation as a leader and facilitator in the dance world.',
    assassins: 'I joined the Assassins Crew in 2000, while living abroad in Heidelberg, Germany. It was an effort for us to remember the roots of the music and the dance forms we were doing and the people in those communities that were sharing with us. We were a conglomerate of professionals and up-and-coming movers and shakers coming together to represent not just on stages and in the industry but in the underground as well, where these dance forms came from. We were not just trying to be successful as artists, we wanted to be community-driven, so taking care of the underground and the people there was important to us. Those lessons and those people inspired me not only to open an Assassins chapter in DC but also to found my own organization, Urban Artistry, dedicated to sharing and preserving these dance cultures.',
    urbanArtistry: 'Upon returning to the US in 2005 following 8 years of working abroad, I wanted to continue the work I was doing, cultivating dance cultures with origins in African American communities through structured mentorship, professional development, and community-building. I founded the non-profit Urban Artistry, Inc., which has been promoting and preserving dance communities in the DMV area and beyond through classes, mentorship, performances, annual events such as the International Soul Society festival, and partnerships with organizations like the Smithsonian Institution and National Park Service to expand the reach of this programming to new and diverse audiences.',
    nextLevel: 'I brought my expertise to Next Level in 2014, its inaugural season, as an Artist Educator for the Senegal program. The potential to serve global communities and shape the global future of Hip Hop inspired me to continue to work with Next Level. Since then, I have contributed my knowledge and skill to building the program as a Consultant, Site Manager, Associate Director, Co-Director, and now Director.',
    accomplishmentsFull: 'I continue to serve as Executive Director of Urban Artistry, which was recently featured at the Library of Congress and the National Museum of African American History. I also sit on advisory panels for the National Endowment for the Arts and The American Folklore Society. As a member of Phil Wiggins Blues House Party, I takes part in performances and educational programs, sharing the connection between blues music and dance.',
    accomplishmentsConclusion: 'I continue to serve as Executive Director of Urban Artistry, which was recently featured at the Library of Congress and the National Museum of African American History. I also sit on advisory panels for the National Endowment for the Arts and The American Folklore Society.',
    accomplishmentsWiggins: 'As a member of Phil Wiggins Blues House Party, I take part in performances and educational programs, sharing the connection between blues music and dance.'
  }
